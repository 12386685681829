import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function TextContent({
  content,
  fontWeight,
  contentAlign,
  classList,
  classes,
  color,
  fontSize,
}) {
  let font
  let textAlign
  let sizeFont

  switch (fontSize) {
    case "16px":
      sizeFont = "content-normal"
      break
    case "18px":
      sizeFont = "content-18"
      break
    case "20px":
      sizeFont = "content-20"
      break
    case "24px":
      sizeFont = "content-24"
      break
    default:
      sizeFont = "content-normal"
  }

  switch (fontWeight) {
    case "Regular":
      font = "font-noto"
      break
    case "Bold":
      font = "font-noto-bold"
      break
    default:
      font = "font-noto"
  }

  switch (contentAlign) {
    case "Center":
      textAlign = "text-center"
      break
    case "Center Mobile":
      textAlign = "text-center lg:text-left"
      break
    case "Center Desktop":
      textAlign = "text-left lg:text-center"
      break
    case "Left":
      textAlign = "text-left"
      break
    case "Right":
      textAlign = "text-right lg:text-left"
      break
    default:
      textAlign = "text-left"
  }

  return content ? (
    <div
      className={`content ${classList || ""} ${
        classes || ""
      } ${font} ${textAlign} ${sizeFont}`}
      style={{ color: color || null }}
      data-layer-component="text-content"
    >
      {documentToReactComponents(content.json)}
    </div>
  ) : null
}

export default TextContent
